<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width: 150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item label="费用">
        <type-cascader
          v-model="filter.typeId"
          info="ExpenseType"
        ></type-cascader>
      </el-form-item>
      <el-form-item label="供应商">
        <base-select info="Supplier" v-model="filter.supplierId"></base-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh"
          >刷新</el-button
        >
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size: 12px"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        label="单据编号"
        prop="billCode"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="单据日期"
        prop="billDate"
        width="100"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.billDate | datetime
        }}</template>
      </el-table-column>
      <el-table-column
        label="供应商"
        prop="supplierName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="项目"
        prop="projectName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="类别"
        prop="pName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="名称"
        prop="typeName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="金额" prop="amount" :width="$g.tableAmountWidth">
        <template slot-scope="scope">{{
          scope.row.amount | thousands(2)
        }}</template>
      </el-table-column>
      <el-table-column
        label="备注"
        prop="remark"
        show-overflow-tooltip
      ></el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import TypeCascader from "@/components/TypeCascader";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
import setName from "@/common/setName";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect,
    TypeCascader,
    BaseSelect,
  },
  data() {
    return {
      filter: {
        begin: this.$moment().subtract(1, "months").format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD"),
      },
      data: [],
    };
  },
  activated() {
    let item = this.$store.state.reportfilter.cache.find(
      (r) => r.name == this.$route.name
    );
    if (item) {
      this.filter = { ...item.filter };
    }
    this.getData();
  },
  methods: {
    getData() {
      this.$store.commit("deleteFilterCache", this.$route.name);
      let params = { ...this.filter };
      if (!params.begin) {
        this.$message.error("请选择开始日期！");
        return;
      }
      if (!params.end) {
        this.$message.error("请选择结束日期！");
        return;
      }
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$get("ProjectExpense/ExpenseDetail", params)
        .then((r) => {
          r.forEach((item) => {
            setName("Project", item, "projectId", "projectName");
            setName("ExpenseType", item, "parentTypeId", "pName");
            setName("ExpenseType", item, "expenseTypeId", "typeName");
            if (item.supplierId) {
              setName("Supplier", item, "supplierId", "supplierName");
            }
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    getSummaries({ columns, data }) {
      let total = 0;
      data.forEach((item) => {
        total += this.amountFormat(item.amount);
      });
      const sums = [
        "合计",
        "-",
        "-",
        "-",
        "-",
        this.thousandsFormat(total),
        "-",
      ];
      return [sums];
    },
    excel() {
      let list = [];
      this.data.forEach((item) => {
        list.push({
          billCode: item.billCode,
          billDate: this.$moment(item.billDate).format("YYYY-MM-DD"),
          supplierName: item.supplierName,
          projectName: item.projectName,
          pName: item.pName,
          typeName: item.typeName,
          amount: item.amount,
          remark: item.remark,
        });
      });
      let header = [
        "单据编号",
        "单据日期",
        "供应商",
        "项目",
        "类别",
        "名称",
        "金额",
        "备注",
      ];
      let column = [
        "billCode",
        "billDate",
        "supplierName",
        "projectName",
        "pName",
        "typeName",
        "amount",
        "remark",
      ];

      toExcel(header, column, list, "项目费用明细");
    },
    dblclickTable(row) {
      if (this.hasPermission("Expense")) {
        this.$router.push({
          name: "ExpenseItem",
          query: { id: row.billId },
        });
      }
    },
  },
};
</script>

<style>
</style>